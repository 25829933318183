<script>
	import { browser } from '$app/environment';
	import CTASection from '$lib/landing-pages/sections/CTASection.svelte';
	import { fadeInOnIntersect } from '@tickrr/ui/actions';
</script>

<div class="grid-background absolute inset-x-0 top-0 z-0 h-screen"></div>
<div
	class="light-effect"
	style="top: 0%; left: 22.5%; transform: rotate(-45deg) translateY(-40%);"
	use:fadeInOnIntersect={{
		delay: 250,
		duration: 2750,
		translate: 'translate-y-12'
	}}
></div>
<div
	class="light-effect"
	style="top: 0%; left: 30%; transform: rotate(-65deg) translateY(-40%);"
	use:fadeInOnIntersect={{
		delay: 750,
		duration: 2750,
		translate: 'translate-y-12'
	}}
></div>

<!-- PAGE CONTENT -->
<div
	id="landing-page"
	class="mx-auto flex max-w-page flex-col justify-center gap-y-20 px-6 max-tablet:mb-12 tablet:mb-20 2xl:px-0"
	class:opacity-0={!browser}
>
	<slot />
</div>

<!-- This goes outside the section because it needs to span the full-width of the screen. -->
<CTASection />
