<script lang="ts">
	import { page } from '$app/stores';

	export let sectionId: string = 'cta';
</script>

<section
	id={sectionId}
	class="relative min-h-96 w-full border-y-2 border-primary-500 px-4 py-16 tablet:px-8 tablet:py-32"
>
	<div class="absolute inset-0">
		<enhanced:img
			alt="Full-screen chart page"
			loading="lazy"
			src="$images/landingpage/chart-page__full-screen.png?enhanced"
			class="h-full w-full object-cover object-top opacity-40"
		/>
	</div>
	<div
		class="relative z-10 mx-auto flex h-full w-full max-w-page flex-col justify-center text-surface-50 tablet:px-6"
	>
		<span class="mb-4 text-5xl font-black text-primary-500 shadow-black text-shadow-md">
			14 days free.
		</span>
		<span class="mb-8 text-5xl font-black shadow-black text-shadow-md">
			Start your trial today.
		</span>
		<div>
			<a
				href={$page.data.profile ? '/checkout' : '/signup?redirectTo=/checkout'}
				class="tablet:btn-wide variant-filled btn tablet:py-4"
			>
				<span> Start your free trial </span>
			</a>
		</div>
	</div>
</section>
